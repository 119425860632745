import React from "react"

const Tag = ({ tag, ...props }) => {
    let className = "tag"

    if (tag.type === "Default") {
        className += " tag--highlight"
    }

    if (tag.type === "Info") {
        //ToDo: change classname
        className += " tag--highlight tag--highlight--blue"
    }

    if (tag.type === "Success") {
        //ToDo: change classname
        className += " tag--highlight tag--highlight--green"
    }

    if (tag.type === "Warning") {
        //ToDo: change classname
        className += " tag--highlight tag--highlight--orange"
    }

    return <div className={className} {...props}>{tag.name}</div>
}

export default Tag
